import type { RouteObject } from "react-router-dom";
import { paths } from "@configs/routes/paths";

export const publicRoutes: RouteObject = {
  path: paths.search,
  async lazy() {
    let { PublicLayout } = await import("@src/pages/public");
    return { Component: PublicLayout };
  },
  children: [
    {
      index: true,
      async lazy() {
        let { SearchAndRegister } = await import("@src/pages/public");
        return { Component: SearchAndRegister };
      },
    },
    {
      async lazy() {
        let { RegisterLayout } = await import("@src/pages/public");
        return { Component: RegisterLayout };
      },
      children: [
        {
          path: `${paths.search}/agent/:id`,
          async lazy() {
            let { AgentRegister } = await import("@src/pages/public");
            return { Component: AgentRegister };
          },
        },
        {
          path: `${paths.search}/association/:id`,
          async lazy() {
            let { AssociationRegister } = await import("@src/pages/public");
            return { Component: AssociationRegister };
          },
        },
      ],
    },
  ],
};
