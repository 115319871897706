import * as yup from "yup";

export type SearchValidationType = yup.InferType<typeof searchValidation>;
export type TSearchAssociationSchema = yup.InferType<
  typeof searchAssociationSchema
>;

export const secureAppStepOne = yup.object().shape({
  email: yup
    .string()
    .email("Enter valid email!")
    .required("Email is required!"),
});

export const searchValidation = yup.object().shape({
  email: yup.string().email().default(""),
  name: yup.string().default(""),
  since: yup.date().default(new Date()),
  until: yup
    .date()
    .default(new Date())
    .test({
      name: "until",
      message: "",
      test(value) {
        return new Date(value) >= new Date(this.parent.since);
      },
    }),
});

export const searchAssociationSchema = yup.object().shape({
  search: yup.string().default(""),
});
