import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { type RootState } from "@src/store/store";

interface StateType {
  email: string | null;
}

const initialState = {
  email: null,
} as StateType;
const userSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<StateType["email"]>) => {
      state.email = action.payload;
    },
  },
});

export const { setEmail } = userSlice.actions;
export default userSlice.reducer;

export const selectSignUpEmail = (state: RootState) => state.signup.email;
