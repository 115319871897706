import React from "react";
import { ThemeProvider } from "styled-components";

interface IThemeProviderProps {
  children: React.ReactNode;
}

const theme = {
  "MMCEPI-light-blue": "#0840A8",
  "MMCEPI-blue": "#132e9c",
  "MMCEPI-blue-text": "#19257c",
  "MMCEPI-yellow": "#f7ce46",
  "light-blue": "#e8e9fd",
  "lighter-blue": "#eaf3ff",
  "mute-light": "#c6c6c6",
  "mute-lighter": "#fafafa",
  white: "#fff",
  "text-dark": "#2e2e33",
  "text-darker": "#161616",
};

export const ThemeProviderStyledComponent = ({
  children,
}: IThemeProviderProps) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
