export enum RunModeEnum {
  DevTest = "devTest",
  Dev = "dev",
  Prod = "prod",
  Stage = "staging",
}

const RUN_MODE: string | undefined = process.env.REACT_APP_RUN_MODE;
const REDIRECT_URL: string | undefined =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_REDIRECT_URL
    : "http://localhost:9143/";

type IAwsConfig = {
  Auth: {
    identityPoolId: string;
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  aws_appsync_graphqlEndpoint: string;
  aws_appsync_region: string;
  aws_appsync_authenticationType: string;
};

export const awsConfig: any = {
  Auth: {
    identityPoolId: "us-east-1:900ffbda-1094-4ca1-8b5a-18569bd495fe",
    region: "us-east-1",
    userPoolId: "us-east-1_YwEZzgqw9",
    userPoolWebClientId: "42b90nkpgahu7dp17l2uf33joh",
    oauth: {
      domain: "realtyfeed-sso.auth.us-east-1.amazoncognito.com",
      scope: [
        "aws.cognito.signin.user.admin",
        "email",
        "openid",
        "profile",
        "phone",
      ],
      redirectSignIn: REDIRECT_URL,
      redirectSignOut: REDIRECT_URL,
      responseType: "code", // or 'token', depending on your use case,
    },
  },
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://xx62adoobbfa7lecedcezpop4q.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
};

export const awsConfigDev: any = {
  Auth: {
    identityPoolId: "us-east-1:76389feb-e688-4ab2-83e5-b5dc1068d507",
    region: "us-east-1",
    userPoolId: "us-east-1_92trFqjMo",
    userPoolWebClientId: "1bqegulojkrtu5puo1fm6poa2g",
    oauth: {
      domain: "realtyfeed-dev.auth.us-east-1.amazoncognito.com",
      scope: [
        "aws.cognito.signin.user.admin",
        "email",
        "openid",
        "profile",
        "phone",
      ],
      redirectSignIn: REDIRECT_URL,
      redirectSignOut: REDIRECT_URL,
      responseType: "code", // or 'token', depending on your use case,
    },
  },
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://i6mvieo3jfc7hgjy6x6khzilni.appsync-api.us-east-1.amazonaws.com/graphql",
  aws_appsync_region: "us-east-1",
};

const config =
  RUN_MODE !== RunModeEnum.Prod && RUN_MODE !== RunModeEnum.Stage
    ? awsConfigDev
    : awsConfig;

export default config;
