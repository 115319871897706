export enum ESidebar {
  ProfileInfo = "ProfileInfo",
  PackageDetails = "PackageDetails",
  PackageDetailsV2 = "PackageDetailsV2",
  CheckOut = "CheckOut",
  CheckOutWithAddCard = "CheckOutWithAddCard",
  CardList = "CardList",
  AddCard = "AddCard",
  Notification = "Notification",
  GroupList = "GroupList",
  GroupMember = "GroupMember",
  BrokerageList = "BrokerageList",
  MlsList = "MlsList",
  ApiKeyDetails = "ApiKeyDetails",
  AvailableGroups = "AvailableGroups",
}
