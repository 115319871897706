import { ReactNode, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider as ReduxProvider } from "react-redux";

import { persistor, store } from "@src/store/store";
import { PersistGate } from "redux-persist/es/integration/react";
import { CustomToastBar } from "@components/common/ToastBar";

// ** Toast
import { ToastBar, Toaster } from "react-hot-toast";

import themeConfig from "@configs/themeConfig";

// ** Spinner (Splash Screen)
import { SpinnerComponent as Spinner } from "@components/common/Spinner";
import { ThemeProviderStyledComponent } from "@components/Providers/ThemeProviderStyledComponent";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundaryFallBack } from "@components/ErrorBoundary";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
    mutations: {
      retry: false,
    },
  },
});

export function Providers({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallBack}>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Suspense fallback={<Spinner />}>
              <ThemeProviderStyledComponent>
                {children}
                <Toaster
                  position={themeConfig.layout.toastPosition}
                  toastOptions={{ duration: 6000 }}
                  containerStyle={{ zIndex: 21474830032 }}
                >
                  {(t) => (
                    <ToastBar
                      toast={t}
                      style={{ padding: 0, overflow: "hidden" }}
                    >
                      {(params) => <CustomToastBar t={t} {...params} />}
                    </ToastBar>
                  )}
                </Toaster>
              </ThemeProviderStyledComponent>
            </Suspense>
          </PersistGate>
        </ReduxProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundary>
  );
}
