import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import breadCrumb from "@src/store/breadCrumbs";
import sidebar from "./sidebar";
import layout from "./layout";
import user from "./user";
import signup from "./signup";
import secureAppSlice from "./secureAppSLice";

const persistBreadCrumbReducer = persistReducer(
  {
    key: "breadCrumb",
    storage,
  },
  breadCrumb
);
const persistUser = persistReducer(
  {
    key: "user",
    storage,
  },
  user
);

const persistSignup = persistReducer(
  {
    key: "signup",
    storage,
  },
  signup
);

const rootReducer = {
  sidebar,
  layout,
  breadCrumb: persistBreadCrumbReducer,
  user: persistUser,
  signup: persistSignup,
  secureAppSlice,
};

export default rootReducer;
