import { Renderable, toast, type Toast } from "react-hot-toast";

import successIcon from "@src/assets/images/icons/ep-success.svg";
import alertIcon from "@src/assets/images/icons/ep-alert.svg";
import warningIcon from "@src/assets/images/icons/ep-warning.svg";
import infoIcon from "@src/assets/images/icons/ep-info.svg";
import closeIcon from "@src/assets/images/icons/ep-close.svg";

import "@styles/pages/customToastBar.scss";
import { Typography } from "../Typography";
import { EFonts } from "@src/core/enum/fonts.enum";
interface ICustomToastBarProps {
  t: Toast;
  message: Renderable;
  icon: Renderable;
}
export const CustomToastBar = ({ t, message, icon }: ICustomToastBarProps) => {
  const type = t.className ?? "success";

  const data = toastDetail[type];

  const messageElement =
    message && typeof message !== "string" ? (
      <Typography
        size={7}
        font={EFonts.MontserratRegular}
        className="messageStyle m-0"
        style={{
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 2 /* number of lines to show */,
          lineClamp: 2,
          WebkitBoxOrient: "vertical",
        }}
      >
        {message?.props?.children}
      </Typography>
    ) : null;

  return (
    <div className="d-flex align-items-start  flex-row  toastContainer gap-1">
      <div
        className="iconContainer d-flex justify-content-center align-items-center"
        style={{
          backgroundColor: data.iconBg,
        }}
      >
        <img src={data.icon} alt="icon" />
      </div>
      <div className="d-flex  gap-0/5 flex-column justify-content-center h-100 align-items-start messageContainer">
        <Typography font={EFonts.MontserratSemiBold} size={5}>
          {data.header}
        </Typography>
        {messageElement}
      </div>
      <button className="closeButtonStyle" onClick={() => toast.dismiss(t.id)}>
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  );
};

const toastDetail: any = {
  success: {
    header: "Success",
    iconBg: "#DDF7E0",
    icon: successIcon,
  },
  error: {
    header: "Error",
    iconBg: "#FFDCE4",
    icon: alertIcon,
  },
  info: {
    header: "Info",
    iconBg: "#EEE8FF",
    icon: infoIcon,
  },
  warning: {
    header: "Warning",
    iconBg: "#FFF3CC",
    icon: warningIcon,
  },

  loading: {
    header: "Warning",
    iconBg: "#FFF3CC",
    icon: warningIcon,
  },
  blank: {
    header: "Warning",
    iconBg: "#FFF3CC",
    icon: warningIcon,
  },
  custom: {
    header: "Warning",
    iconBg: "#FFF3CC",
    icon: warningIcon,
  },
};
