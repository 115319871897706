import type { RouteObject } from "react-router-dom";
import { paths } from "@configs/routes/paths";

export const authRoutes: RouteObject = {
  async lazy() {
    let { AuthLayout } = await import("@src/pages/auth");
    return { Component: AuthLayout };
  },
  children: [
    // {
    //   path: paths.emailCheck,
    //   async lazy() {
    //     let { EmailCheck } = await import("@src/pages/auth");
    //     return { Component: EmailCheck };
    //   },
    // },
    {
      path: paths.login,
      async lazy() {
        let { Login } = await import("@src/pages/auth");
        return { Component: Login };
      },
    },
    {
      path: paths.initialProfileSetup,
      async lazy() {
        let { InitialProfileSetup } = await import("@src/pages/auth");
        return { Component: InitialProfileSetup };
      },
    },
    // {
    //   path: paths.signup,
    //   async lazy() {
    //     let { Signup } = await import("@src/pages/auth");
    //     return { Component: Signup };
    //   },
    // },
    // {
    //   path: paths.confirmCode,
    //   async lazy() {
    //     let { ConfirmCode } = await import("@src/pages/auth");
    //     return { Component: ConfirmCode };
    //   },
    // },
    // {
    //   path: paths.forgotPassword,
    //   async lazy() {
    //     let { ForgetPassword } = await import("@src/pages/auth");
    //     return { Component: ForgetPassword };
    //   },
    // },
    // {
    //   path: paths.changePassword,
    //   async lazy() {
    //     let { ChangePassword } = await import("@src/pages/auth");
    //     return { Component: ChangePassword };
    //   },
    // },
  ],
};
