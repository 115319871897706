export const externalsLink = {
  aboutUs: "https://cepi.eu/en/about-us",
  association: "https://cepi.eu/en/the-association",
  cepiBoard: "https://cepi.eu/en/the-cepi-board",
  ourMembers: "https://cepi.eu/en/our-members",
  team: "https://cepi.eu/en/the-team",
  partnerShips: "https://cepi.eu/en/sponsors-and-partners",
  inclusionAndDiversity: "https://cepi.eu/en/inclusion-and-diversity",
  codes: "https://cepi.eu/en/codes",
  policyPriorities: "https://cepi.eu/en/policy-priorities",
  energy: "https://cepi.eu/en/energy",
  aml: "https://cepi.eu/en/aml",
  regulation: "https://cepi.eu/en/regulation",
  str: "https://cepi.eu/en/str",
  mmcepi: "https://cepi.eu/en/mmcepi",
  education: "https://cepi.eu/en/education",
  eureducProgramme: "https://cepi.eu/en/eureduc-programme",
  eureducNetwork: "https://cepi.eu/en/eureduc-network",
  eureducAlumniCommunity: "https://cepi.eu/en/eureduc-alumni-community",
  eureducThesesLibrary: "https://cepi.eu/en/eureduc-theses-library",
  attestationOfRecognition: "https://cepi.eu/en/attestation-of-recognition",
  news: "https://cepi.eu/en/news",
  press: "https://cepi.eu/en/press",
  podcast: "https://cepi.eu/en/podcast",
  calendar: "https://cepi.eu/en/calendar",
  workWithUs: "https://cepi.eu/en/work-with-us",
  legalDisclaimer: "https://cepi.eu/en/legal-disclaimer",
  privacyPolicy: "https://cepi.eu/en/privacy-policy",
  cookiePolicy: "https://cepi.eu/en/cookie-policy",
  linkedin:
    "https://linkedin.com/company/european-association-of-real-estate-professions-cepi-aisbl",
  facebook: "https://www.facebook.com/cepi.eu/",
  spotify: "https://podcasters.spotify.com/pod/show/cepirealestate",
  aboutUsFooter: "https://cepi.eu/en/mmcepi",
  termsAndConditions: "https://cepi.eu/en/privacy-policy",
};
