import React from "react";
import { LinearLoading } from "@components/common/Spinner/LinearLoading";

interface ILoadingWrapperProps {
  isFetching: boolean;
  children?: React.ReactNode;
  Loading?: React.ElementType;
}

export const LoadingWrapper = ({
  children,
  isFetching,
  Loading,
}: ILoadingWrapperProps) => {
  const CompLoading = Loading || LinearLoading;

  if (isFetching) return <CompLoading />;
  return <>{children}</>;
};
